<template>
    <div class="conbox flexCloumn" style="padding:20px;">
        <!-- <div style="display: flex;">
            <div id="yjChart10" class="chart" style="width:100%">
            </div>
            <div id="yjChart11" class="chart" style="width:100%">
            </div>
        </div> -->

        <div class="conbox flexCloumn" style="width:100%;background-color:#fff;border-radius:4px;">
            <div class="boxinner flexStart" style="width:100%;">
                <img src="/img/home_title_icon.png" alt="">
                <div class="boxtitle">南京市中等职业学校就业情况分布图</div>
            </div>
            <div style="width:100%;display: flex;flex-flow: row wrap;justify-content: space-around;margin-top: 30px;">
                <div id="yjChart1" class="chart"></div>
                <div id="yjChart2" class="chart"></div>
                <div id="yjChart21" class="chart"></div>

                <div id="yjChart3" class="chart"></div>
                <div id="yjChart4" class="chart"></div>
                <div id="yjChart5" class="chart"></div>
                <div id="yjChart6" class="chart"></div>
                <div id="yjChart7" class="chart"></div>
                <div class="chart"></div>
                <div id="yjChart8" class="chart"></div>
                <div id="yjChart20" class="chart"></div>
                <div class="chart"></div>

            </div>
        </div>

        <!-- <div style=" text-align: center; padding-bottom: 10px;" v-if="xuezhiList.length >= 1"
                    @change="changeXuezhi">
                    <el-radio-group v-model="xuezhiName">
                        <el-radio-button label="" checked>全部学制</el-radio-button>

                        <el-radio-button v-for="item in xuezhiList" :label="item" :key="city">{{ item
                            }}</el-radio-button>
                    </el-radio-group>
                </div> -->

        <div id="anchor_major" style="width:100%;height: 1px;"></div>
        <div class="mt20" style="width: 100%;background-color:#fff;border-radius:4px;">
            <div class="boxinner flexStart" style="">
                <img src="/img/home_title_icon.png" alt="">
                <div class="boxtitle">各专业大类就业率统计表</div>
            </div>
            <div id="yjChart30" style="width:100%;height:400px;"></div>
        </div>
        <div class="mt20" style="width: 100%;background-color:#fff;border-radius:4px;">
            <div class="boxinner flexStart" style="">
                <img src="/img/home_title_icon.png" alt="">
                <div class="boxtitle">各专业大类对口率统计表</div>
            </div>
            <div id="yjChart31" style="width:100%;height:400px;"></div>
        </div>

        <div id="anchor_city" style="width:100%;height: 1px;"></div>
        <div class="mt20" style="padding:0 50px 50px 50px;width: 100%;background-color:#fff;border-radius:4px;">
            <div class="boxinner flexStart" style="width:100%;margin-left:-50px;">
                <img src="/img/home_title_icon.png" alt="">
                <div class="boxtitle">南京市中等职业学校专业大类毕业生人数统计表</div>
            </div>

            <table class="dttable" v-if="MajorTongji.length > 0">
                <tr>
                    <td style="width:4.96%;color:#333;font-weight:700;">学校名称</td>
                    <td style="width:4.75%;color:#333;font-weight:700;" v-for="(mj, i) in MajorTongji[0].major_type"
                        :key="i">
                        <span :class="(mj.name == '合计') ? 'color_hj' : ''">{{
                            mj.name }}</span>
                    </td>
                </tr>
                <tr v-for="(sch, i) in MajorTongji" :key="'sch_' + i">
                    <td :class="(sch.platform == '合计') ? 'color_hj' : ''" style="color:#333;font-weight:700;">{{
                        sch.platform }}</td>
                    <td style="color:#333;" v-for="(mj, mi) in sch.major_type" :key="'major_' + mi">
                        <span
                            :class="(sch.name == '合计' || (mi == sch.major_type.length - 1) || (i == MajorTongji.length - 1)) ? 'color_hj' : ''">{{
                            mj.data ?
                            mj.data.bys_num
                            :
                            '' }}</span>
                    </td>
                </tr>
            </table>
        </div>


        <div style="height:20px;color:#fff"></div>



    </div>
</template>

<script>
import echartMixins from "../../js/resizeMixins";

let colors = require("./color.js")
export default {
    props: ["tPmid"],
    components: {

    },
    data() {
        return {
            DataInfo: null,
            xuezhiList: [],
            xuezhiName: "",
            MajorTongji: [],
            enterTypeList: {}
        }
    },
    mixins: [echartMixins],
    mounted() {


        this.getData()

    },
    methods: {
        changeXuezhi() {
            this.getData()
        },
        getData() {
            let data = { tpmid: "all", xuezhi: this.xuezhiName }
            this.$http.post("/api/get_jiuye_tjtable2", data).then(res => {

                this.DataInfo = res.data
                this.xuezhiList = res.data.xuezhi.map(e => { return e.class_xz })
                setTimeout(() => {
                    this.initPie()
                    this.initColumn()
                    this.initMcolumn()
                }, 1000);
                this.getMajorTongji()
            })


        },
        getMajorTongji() {
            this.$http.post("/api/major_type_tongji", { xuezhi: this.xuezhiName }).then(res => {
                let arr = res.data

                res.data.map(a => {
                    let mobj = JSON.parse(JSON.stringify(a.major_type[0]))
                    // console.log(145, m.name, a)
                    mobj.name = '合计'
                    // 循环加专业合计
                    let school_total = 0
                    a.major_type.map(mitem => {
                        if (mitem.data) {
                            school_total += mitem.data.bys_num
                        }
                    })
                    if (!mobj.data) {
                        mobj.data = {}
                    }
                    mobj.data.bys_num = school_total
                    a.major_type.push(mobj)
                })



                let obj = JSON.parse(JSON.stringify(res.data[0]))
                console.log(res.data)
                if (obj.major_type) {
                    obj.major_type.map((m, index) => {
                        let total_by = 0
                        res.data.map(a => {
                            // console.log(145, m.name, a)
                            if (a.major_type[index].data) {
                                total_by += a.major_type[index].data.bys_num
                            }
                        })
                        // console.log(150, m)
                        if (!m.data) {
                            m.data = {}
                        }
                        m.data.bys_num = total_by || ''
                    })

                }
                obj.platform = '合计'
                arr.push(obj)


                this.MajorTongji = arr
            })
        },
        getEnterTypePieTongji() {
            return new Promise((resolve, reject) => {
                this.$http.post("/api/enter_chart_tongji").then(res => {

                    this.enterTypeList = res.data
                    resolve()
                })
            })

        },
        initPie() {

            let option = {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        // color: 'white'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     top: "10%",
                //     left: 'center'
                // },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderRadius: 10,
                            // borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                formatter: '{b}{c}人({d}%)',
                                textStyle: {
                                    fontWeight: 'normal',
                                    fontSize: 12,
                                    color: "#000"
                                }
                            }
                        },
                        data: [

                        ],
                        color: ['#78A3E0', '#6055BB', '#88C6BB', '#E9A65D', '#78A3E0', '#6055BB', '#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            //就业率
            let percent = parseInt(this.DataInfo.jiuye_num / this.DataInfo.bys_num * 10000) / 100

            let xdata = [{ value: percent, name: '就业率' }, { value: (100 - percent).toFixed(2), name: '其他' }];
            option.series[0].name = "就业率"
            option.series[0].data = xdata;
            option.title.text = "就业率"
            this.$echarts.init(document.getElementById('yjChart1')).setOption(option);


            percent = parseInt(this.DataInfo.duikou_num / (this.DataInfo.bys_num - this.DataInfo.daiye_num) * 10000) / 100

            xdata = [{ value: percent, name: '对口率' }, { value: (100 - percent).toFixed(2), name: '不对口' }];
            option.series[0].name = "对口就业率"
            option.series[0].data = xdata;
            option.title.text = "对口就业率"
            this.$echarts.init(document.getElementById('yjChart2')).setOption(option);

            //就业去向
            xdata = this.DataInfo.quxiang.filter(e => !!e.quxiang).map(e => { return { value: e.n, name: e.quxiang } });
            option.series[0].name = "就业去向"
            option.series[0].data = xdata;
            option.title.text = "就业去向分组"
            this.$echarts.init(document.getElementById('yjChart3')).setOption(option);
            //就业产业
            xdata = this.DataInfo.chanye.map(e => { return { value: e.n, name: e.chanye } });
            option.title.text = "就业产业分组"
            option.series[0].name = "就业产业"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart4')).setOption(option);

            //就业地域
            xdata = this.DataInfo.diyu.map(e => { return { value: e.n, name: e.diyu } });
            option.title.text = "就业地域分组"
            option.series[0].name = "就业地域"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart5')).setOption(option);
            //就业地点
            xdata = this.DataInfo.didian.map(e => { return { value: e.n, name: e.didian } });
            option.title.text = "就业地点分组"
            option.series[0].name = "就业地点"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart6')).setOption(option);

            //就业渠道
            xdata = this.DataInfo.qudao.map(e => { return { value: e.n, name: e.qudao } });
            option.title.text = "就业渠道分组"
            option.series[0].name = "就业渠道"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart7')).setOption(option);

            // //资格证书情况
            // xdata = this.DataInfo.zhengshu.map(e => { return { value: e.n, name: e.zhengshu } });
            // option.title.text = "资格证书情况"
            // option.series[0].name = "资格证书情况"
            // option.series[0].data = xdata;
            // this.$echarts.init(document.getElementById('yjChart8')).setOption(option);
            //满意度
            xdata = this.DataInfo.manyidu.map(e => { return { value: e.n, name: e.manyidu } });
            option.title.text = "满意度情况"
            option.series[0].name = "满意度情况"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart8')).setOption(option);
            //保险情况
            xdata = this.DataInfo.baoxian.map(e => { return { value: e.n, name: e.wuxian } });
            option.title.text = "保险情况"
            option.series[0].name = "保险情况"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart20')).setOption(option);


            // this.$echarts.init(document.getElementById('schoolChart100')).setOption(option);


            this.getEnterTypePieTongji().then(_ => {
                console.log(341, this.enterTypeList)
                let enterType = this.enterTypeList.enterType;
                for (let item of enterType) {
                    if (!item.enter_type) {
                        item.enter_type = "未知类型"
                    }
                }
                xdata = enterType.map(e => { return { value: e.n, name: e.enter_type } })
                console.log(341, xdata)
                option.title.text = "企业类型"
                option.series[0].name = "企业类型"
                option.series[0].data = xdata
                this.$echarts.init(document.getElementById('yjChart21')).setOption(option);
            })
        },
        initMcolumn() {//多个柱子的图

            let xData = [], valueData = [[], [], []], pctData = [];

            let majorDic = {}
            let majors_bys = [];
            for (let item of this.DataInfo.majors_bys) {
                if (item.major_type != "总计") {
                    majors_bys.push(item)
                }
            }
            this.DataInfo.majors_bys = majors_bys

            let majors_jiuye = [];
            for (let item of this.DataInfo.majors_jiuye) {
                if (item.major_type != "总计") {
                    majors_jiuye.push(item)
                }
            }
            this.DataInfo.majors_jiuye = majors_jiuye

            let majors_duikou = [];
            for (let item of this.DataInfo.majors_duikou) {
                if (item.zhuanye_type != "总计") {
                    majors_duikou.push(item)
                }
            }
            this.DataInfo.majors_duikou = majors_duikou


            for (let item of this.DataInfo.majors_bys) {
                if (!majorDic[item.major_type]) {
                    majorDic[item.major_type] = { bys: item.bys_num, duikou: 0, jiuye: 0 }
                } else {
                    majorDic[item.major_type].bys = item.bys_num
                }
            }

            for (let item of this.DataInfo.majors_jiuye) {
                if (!majorDic[item.major_type]) {
                    majorDic[item.major_type] = { bys: item.jiuye_num, duikou: 0, jiuye: item.jiuye_num }
                } else {
                    majorDic[item.major_type].jiuye = item.jiuye_num
                }
            }

            for (let item of this.DataInfo.majors_duikou) {
                if (!majorDic[item.zhuanye_type]) {
                    majorDic[item.zhuanye_type] = { bys: item.n.n, duikou: item.n, jiuye: item.n }
                } else {
                    majorDic[item.zhuanye_type].duikou = item.n
                }
            }

            for (let k in majorDic) {
                xData.push(k)
                valueData[0].push(majorDic[k].bys)
                valueData[1].push(majorDic[k].jiuye)
                valueData[2].push(majorDic[k].duikou)
            }




            //计算就业率

            for (let i = 0; i < xData.length; i++) {
                let jyl = Math.round(valueData[1][i] / valueData[0][i] * 10000) / 100;
                let dkl = Math.round(valueData[2][i] / valueData[0][i] * 10000) / 100;
                let tdata = { name: xData[i], jyl, dkl, total: valueData[0][i], jycount: valueData[1][i], dkcount: valueData[2][i] }
                pctData.push(tdata)
            }
            pctData = pctData.sort((a, b) => { return b.jyl - a.jyl })

            let option = {
                title: {
                    text: "",
                    left: "center",
                    textStyle: {
                        color: 'white'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#fff'
                        }
                    },
                    function(params) {
                        let formaterstr = '', inner0 = ''
                        inner0 = `<span>${params[0].value}%</span>`
                        formaterstr = inner0
                        // 这里去掉了 title，只保留了 series 名称和数据的描述
                        return formaterstr;
                    }
                },
                // legend: {
                //     left: "center",
                //     top: "5%",

                //     data: ['就业率', '对口率']
                // },
                grid: {
                    top: 30,
                    left: "5%",
                    right: "5%",
                    bottom: "25%"
                },

                xAxis: [
                    {
                        type: 'category',
                        data: pctData.map(e => { return e.name }),
                        axisPointer: {
                            type: 'shadow'
                        },
                        itemStyle: {
                            color: function (params) {
                                return colors[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                        },
                        axisLabel: {
                            rotate: 45, // 旋转角度为45度
                            // color: "#ffffff"
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '百分比',
                        splitLine: { show: false },
                        axisLabel: {
                            // color: 'white'

                        },

                    }
                ],
                color: ['#6055BB', '#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                series: [
                    {
                        name: '就业率',
                        type: 'bar',
                        barMaxWidth: "40",
                        data: pctData.map(e => { return e.jyl }),
                        label: {
                            show: true,
                            position: 'inside',
                        }

                    }
                ]
            };

            this.$echarts.init(document.getElementById('yjChart30')).setOption(option);

            option.title.text = ''
            option.series[0].name = '对口率'
            option.series[0].data = pctData.map(e => { return e.dkl })
            this.$echarts.init(document.getElementById('yjChart31')).setOption(option);


        },
        initColumn() {

            let xData = [];
            let xValue = [];

            let option = {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        color: 'white'
                    }
                },
                tooltip: {
                    show: true
                },
                color: ['#6055BB', '#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisLabel: {
                        color: 'white'

                    },
                    splitLine: { show: false }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: 'white'

                    },
                    splitLine: { show: false }
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        barWidth: 30,
                        showBackground: true,

                        itemStyle: {
                            color: function (params) {
                                return colors[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: 10
                        }
                    }
                ]
            };
            //就业合同
            let getPropValue = (arr, name, code) => {
                let value = 0;
                for (let item of arr) {
                    if (item[code] == name) {
                        value = item.n
                        break
                    }
                }
                return value
            }
            xData.push({ name: "未签合同", value: getPropValue(this.DataInfo.hetong, "未签合同", "hetong") })
            xData.push({ name: "1年及以内", value: getPropValue(this.DataInfo.hetong, "1年及以内", "hetong") })
            xData.push({ name: "1～2(含)年", value: getPropValue(this.DataInfo.hetong, "1～2(含)年", "hetong") })
            xData.push({ name: "2～3(含)年", value: getPropValue(this.DataInfo.hetong, "2～3(含)年", "hetong") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "就业合同签订情况（人数）"

            // this.$echarts.init(document.getElementById('yjChart10')).setOption(option);


            //起薪情况
            xData = [];
            xData.push({ name: "2490及以下", value: getPropValue(this.DataInfo.salary, "2490及以下", "salary") })
            xData.push({ name: "2490～4000", value: getPropValue(this.DataInfo.salary, "2490～4000", "salary") })
            xData.push({ name: "4000～5000", value: getPropValue(this.DataInfo.salary, "4000～5000", "salary") })
            xData.push({ name: "5000～6000", value: getPropValue(this.DataInfo.salary, "5000～6000", "salary") })
            xData.push({ name: "6000以上", value: getPropValue(this.DataInfo.salary, "6000以上", "salary") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "起薪情况（平均起薪" + this.DataInfo.salary_avg + "）"

            // this.$echarts.init(document.getElementById('yjChart11')).setOption(option);


            //社会保险情况（人数）
            xData = [];
            xData.push({ name: "没有社保", value: getPropValue(this.DataInfo.baoxian, "没有社保", "wuxian") })
            xData.push({ name: "三险", value: getPropValue(this.DataInfo.baoxian, "三险", "wuxian") })
            xData.push({ name: "五险", value: getPropValue(this.DataInfo.baoxian, "五险", "wuxian") })
            xData.push({ name: "三险一金", value: getPropValue(this.DataInfo.baoxian, "三险一金", "wuxian") })
            xData.push({ name: "五险一金", value: getPropValue(this.DataInfo.baoxian, "五险一金", "wuxian") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "社会保险情况（人数）"

            // this.$echarts.init(document.getElementById('yjChart12')).setOption(option);



            //就业满意度情况（人数）
            xData = [];
            xData.push({ name: "无法评估", value: getPropValue(this.DataInfo.manyidu, "无法评估", "manyidu") })
            xData.push({ name: "不满意", value: getPropValue(this.DataInfo.manyidu, "不满意", "manyidu") })
            xData.push({ name: "比较满意", value: getPropValue(this.DataInfo.manyidu, "比较满意", "manyidu") })
            xData.push({ name: "满意", value: getPropValue(this.DataInfo.manyidu, "满意", "manyidu") })
            xData.push({ name: "非常满意", value: getPropValue(this.DataInfo.manyidu, "非常满意", "manyidu") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "就业满意度情况（人数）"

            // this.$echarts.init(document.getElementById('yjChart13')).setOption(option);

        }


    }

}

</script>

<style scoped>
.chart {
    width: 33.3%;
    height: 300px;
}

.color_hj {
    color: #6055BB !important;
    font-weight: 700;
}
</style>