<template>
    <div class="conbox flexCloumn" style="padding:20px;">
        <div id="anchor_school" style="width:100%;height: 1px;"></div>
        <div class="conbox" style="width:100%">
            <div style="width: 50%;background-color:#fff;border-radius:4px;">

                <!-- <dv-border-box-13 style="height: auto;" :reverse="true"> -->
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">各个学校就业率统计图</div>
                </div>
                <div id="chart_sch_ever_jyrate" style="height: 690px;"></div>
                <!-- </dv-border-box-13> -->
            </div>

            <div class="ml20" style="width: calc( 50% - 20px);background-color:#fff;border-radius:4px;">
                <!-- <dv-border-box-13 style="height: auto;" :reverse="true"> -->
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">学生就业人数统计</div>
                </div>

                <!-- <div class="boxinner" style="padding-bottom: 0;">
                    <div class="boxtitle">学生就业人数统计</div>
                </div> -->
                <div id="chart_sch" style="height: 690px;"></div>

                <!-- </dv-border-box-13> -->

            </div>
        </div>

        <div class="conbox mt20" style="width:100%">
            <div style="width: 50%;background-color:#fff;border-radius:4px;">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">企业类型对比图</div>
                </div>
                <div id="chart_2" style="height: 400px;width: 100%;"></div>
            </div>
        </div>

        <div class="conbox mt20" style="width:100%;flex-wrap:wrap;">
            <!-- <div style="width: 100%;">
                <div id="schoolsChart_1" style="height: 500px;width: 100%;"></div>
            </div> -->
            <div style="width: 100%;background-color:#fff;border-radius:4px;">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">各个学校对口就业率统计图</div>
                </div>
                <div id="schoolsChart_2" style="height: 500px;width: 100%;"></div>
            </div>
            <div class="mt20" style="width: 100%;background-color:#fff;border-radius:4px;">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">各个学校毕业生满意度统计图</div>
                </div>
                <div id="schoolsChart_3" style="height: 500px;width: 100%;"></div>
            </div>
            <div class="mt20" style="width: 100%;background-color:#fff;border-radius:4px;">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">各学校合同签订情况</div>
                </div>
                <div id="schoolsChart_4" style="height: 500px;width: 100%;"></div>
            </div>
            <div class="mt20" style="width: 100%;background-color:#fff;border-radius:4px;">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">各个学校的薪资对比图</div>
                </div>
                <div id="schoolsChart_5" style="height: 500px;width: 100%;"></div>
            </div>
            <div class="mt20" style="width: 100%;background-color:#fff;border-radius:4px;">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">各专业大类的薪资平均值对比图</div>
                </div>
                <div id="schoolsChart_6" style="height: 500px;width: 100%;"></div>
            </div>
        </div>
    </div>
</template>

<script>
let colors = require("./color.js")
export default {
    data() {
        return {
            Tongji: {
                data: [], colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                unit: '企业数',
                showValue: true
            }
        };
    },
    mounted() {
        this.getEnterData()
        this.getTongji()


    },
    methods: {
        getEnterData() {
            let data = {
                page: 1,
                keyword: "",
                enter_type: "",
                enter_hy: "",
                school_id: "",
                showloading: false,
                tjtype: ""
            }
            this.$http.post("/api/jyqx_tongji", data).then(res => {
                let arr = []
                for (let item of res.data) {
                    arr.push({ name: item.jyqx, value: item.nums })
                }
                arr = arr.sort((a, b) => { return b.value - a.value })
                this.Tongji.data = arr

            })
        },
        getTongji() {

            this.$http.post("/api/enter_chart_tongji", { tpmid: this.schoolId }).then(res => {

                this.DataList = res.data
                setTimeout(() => {
                    this.initColumn()
                }, 500);


                this.getSchData()
            })
        },
        getSchData() {
            let _this = this
            this.$http.post("/api/get_sch_multicount").then(res => {

                // setTimeout(() => {
                _this.initchart_sch_ever_jyrate(res.data.schools)
                _this.initEverSchools(res.data.schools, res.data.majorTypes)
                // }, 1000);
            })

            this.$http.post("/api/get_sch_stucount").then(res => {
                this.initSchColumn(res.data)

            })

        },

        initColumn() {

            let xData = [];
            let xValue = [];

            let option = {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        color: 'white'
                    }
                },
                grid: {
                    top: '5%',
                    bottom: '10%'

                },
                tooltip: {
                    show: true,
                    trigger: 'axis',
                },
                color: colors,
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        // color: 'white',
                        rotate: 45, // 旋转角度为45度
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        // color: 'white'

                    },
                    splitLine: { show: false }
                },
                series: [
                    {
                        name: "就业人数",
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        barMaxWidth: 20,
                        showBackground: false,
                        itemStyle: {
                            color: function (params) {
                                return colors[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: 10
                        }
                    },

                ]
            };
            //城市
            let getPropValue = (arr, name, code) => {
                let value = 0;
                for (let item of arr) {
                    if (item[code] == name) {
                        value = item.n
                        break
                    }
                }
                return value
            }
            let areas = this.DataList.areas;
            /*for (let item of areas) {
                if (item.cname == "市辖区") {
                    item.cname = item.province
                }
            }*/
            option.xAxis.data = areas.map(e => { return e.name })
            option.series[0].data = this.DataList.areas.map(e => { return e.num })
            option.title.text = "企业所在城市对比图"
            option.grid.bottom = 200
            // this.$echarts.init(document.getElementById('chart_1')).setOption(option);

            option.grid.bottom = 100
            let enterType = this.DataList.enterType;
            for (let item of enterType) {
                if (!item.enter_type) {
                    item.enter_type = "未知类型"
                }
            }
            option.xAxis.data = enterType.map(e => { return e.enter_type })
            option.series[0].data = enterType.map(e => { return e.n })
            option.title.text = ""
            //option.xAxis.axisLabel.rotate = 90

            this.$echarts.init(document.getElementById('chart_2')).setOption(option);

            option.xAxis.axisLabel.rotate = 45
            option.grid.bottom = 90
            let enter_hy = this.DataList.enter_hy;
            for (let item of enter_hy) {
                if (!item.enter_hy) {
                    item.enter_hy = "未知行业"
                }
            }
            option.xAxis.data = enter_hy.map(e => { return e.enter_hy })
            option.series[0].data = enter_hy.map(e => { return e.n })
            option.title.text = "企业行业对比图"

            // this.$echarts.init(document.getElementById('chart_3')).setOption(option);


            let guimo = this.DataList.guimo;
            for (let item of guimo) {
                if (!item.guimo) {
                    item.guimo = "未知"
                }
                if (item.guimo == '-') {
                    item.guimo = "个体户"
                }
            }
            option.xAxis.data = guimo.map(e => { return e.guimo })
            option.series[0].data = guimo.map(e => { return e.n })
            option.title.text = "企业规模对比图"

            // this.$echarts.init(document.getElementById('chart_4')).setOption(option);



        },

        initSchColumn(resData) {
            resData = resData.reverse()
            let reverseColor = JSON.parse(JSON.stringify(colors))
            reverseColor = reverseColor.reverse()
            let xData = resData.map(e => { return e.platform });
            console.log(xData)
            let xValue = [];
            let option = {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        color: 'white'
                    }
                },
                grid: {
                    left: 190, top: 0,


                },
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    title: {
                        show: false
                    },
                    formatter: function (params) {
                        let index = params[0].dataIndex
                        let info = resData[index]
                        // console.log(279, info)

                        let formaterstr = '', inner0 = '',
                            inner1 = '',
                            inner2 = ''
                        let p0 = undefined
                        let p1 = undefined
                        let p2 = undefined
                        inner0 = ` <span>毕业生总数:${info.bycount}</span>
                                <br/>`
                        inner1 = ` <span>就业人数:${info.jycount}</span>
                                <br/>`
                        inner2 = ` <span>待业人数:${info.dycount}</span>
                                <br/>`
                        formaterstr = inner0 + inner1 + inner2
                        // 这里去掉了 title，只保留了 series 名称和数据的描述
                        return formaterstr;
                    }
                },
                xAxis: {
                    type: 'value',
                    data: resData.map(e => { return e.platform }),
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        // color: 'white'

                    },
                    splitLine: { show: false }
                },
                yAxis: {
                    data: xData,
                    type: 'category',
                    axisLabel: {
                        // color: 'white'
                    },
                    splitLine: { show: false }
                },
                series: [
                    // {
                    //     name: "毕业生总数",
                    //     data: resData.map(e => { return e.bycount }),
                    //     type: 'bar',
                    //     barMaxWidth: 20,
                    //     showBackground: false,
                    //     itemStyle: {
                    //         color: function (params) {

                    //             return colors[params.dataIndex];
                    //         },
                    //         borderRadius: [10, 10, 0, 0],
                    //         shadowBlur: 1, // 设置阴影模糊度
                    //         shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                    //     },
                    //     label: {
                    //         show: true,
                    //         position: 'right',
                    //         fontSize: 10
                    //     }
                    // },
                    {
                        name: "就业人数",
                        data: resData.map(e => { return e.jycount }),
                        type: 'bar',
                        barMaxWidth: 20,
                        showBackground: false,
                        itemStyle: {
                            color: function (params) {

                                return reverseColor[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                        },
                        // label: {
                        //     show: true,
                        //     position: 'right',
                        //     fontSize: 10
                        // }
                    },
                    // {
                    //     name: "待业人数",
                    //     data: resData.map(e => { return e.dycount }),
                    //     type: 'bar',
                    //     barMaxWidth: 20,
                    //     showBackground: false,
                    //     itemStyle: {
                    //         color: function (params) {

                    //             return colors[params.dataIndex];
                    //         },
                    //         borderRadius: [10, 10, 0, 0],
                    //         shadowBlur: 1, // 设置阴影模糊度
                    //         shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                    //     },
                    //     label: {
                    //         show: true,
                    //         position: 'right',
                    //         fontSize: 10
                    //     }
                    // }
                ]
            };

            this.$echarts.init(document.getElementById('chart_sch')).setOption(option);
            let jycount = 0, sxcount = 0, bycount = 0, dycount = 0;
            for (let item of resData) {
                jycount += item.jycount
                sxcount += item.sxcount
                bycount += item.bycount
                dycount += item.dycount
            }
            $("#jycount").text(jycount)
            $("#sxcount").text(sxcount)
            $("#bycount").text(bycount)
            $("#dycount").text(dycount)
            console.log(441, dycount)

        },

        initchart_sch_ever_jyrate(resData) {
            resData = resData.reverse()
            let reverseColor = JSON.parse(JSON.stringify(colors))
            reverseColor = reverseColor.reverse()
            let xData = resData.map(e => { return e.platform });
            // console.log(xData)
            let xValue = [];
            let option = {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        color: 'white'
                    }
                },
                grid: {
                    left: 190, top: 0,
                },
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    title: {
                        show: false
                    },
                    formatter: function (params) {
                        let index = params[0].dataIndex
                        let info = resData[index]
                        // console.log(279, info)

                        let formaterstr = '', inner0 = '',
                            inner1 = '',
                            inner2 = ''
                        let p0 = undefined
                        let p1 = undefined
                        let p2 = undefined
                        inner0 = ` <span>就业率:${info.jy_percent}%</span>
                                <br/>`
                        inner1 = ` <span>就业人数:${info.jycount}</span>
                                <br/>`
                        inner2 = ` <span>待业人数:${info.dycount}</span>
                                <br/>`
                        formaterstr = inner0
                        // 这里去掉了 title，只保留了 series 名称和数据的描述
                        return formaterstr;
                    }
                },
                xAxis: {
                    type: 'value',
                    data: resData.map(e => { return e.platform }),
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        // color: 'white'

                    },
                    splitLine: { show: false }
                },
                yAxis: {
                    data: xData,
                    type: 'category',
                    axisLabel: {
                        // color: 'white'
                    },
                    splitLine: { show: false }
                },
                series: [
                    {
                        name: "就业率",
                        data: resData.map(e => { return e.jy_percent }),
                        type: 'bar',
                        barMaxWidth: 20,
                        showBackground: false,
                        itemStyle: {
                            color: function (params) {

                                return reverseColor[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                        },
                    },
                ]
            };

            this.$echarts.init(document.getElementById('chart_sch_ever_jyrate')).setOption(option);
        },
        initEverSchools(resData, majors) {
            let dataAxis = this._lo.map(resData, 'platform')
            let data = this._lo.map(resData, 'jy_percent')

            let option = {
                title: {
                    text: '各个学校就业率统计图',
                    left: 'center',
                    textStyle: {
                        color: 'white'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#fff'
                        }
                    },
                },
                grid: {
                    top: "5%",
                    left: "5%",
                    right: "5%",
                    bottom: "30%"
                },
                xAxis: {
                    type: 'category',
                    data: dataAxis,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        color: 'white',
                    },
                    itemStyle: {
                        color: function (params) {
                            return colors[params.dataIndex];
                        },
                        borderRadius: [10, 10, 0, 0],
                        shadowBlur: 1, // 设置阴影模糊度
                        shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
                    },
                    axisLabel: {
                        rotate: 45, // 旋转角度为45度
                        // color: "#ffffff"
                    }
                },
                yAxis: {
                    type: 'value',
                    name: '百分比',
                    splitLine: { show: false },
                    // axisLabel: { color: '#fff' },
                },
                color: ['#6055BB', '#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                series: [
                    {
                        type: 'bar',
                        barMaxWidth: 40,

                        data: data,
                        label: {
                            show: true,
                            position: 'inside',
                            // formatter: function (params) { //标签内容
                            //   return params.value + '%'
                            // },
                        },
                    }
                ]
            };
            let myChart = null
            // let myChart = this.$echarts.init(document.getElementById('schoolsChart_1'));
            // myChart.setOption(option)

            data = this._lo.map(resData, 'dkjy_percent')
            option.title.text = ""
            option.grid.top = 50
            option.tooltip.formatter = function (params) {
                let formaterstr = '', inner0 = ''
                inner0 = `<span>${params[0].value}%</span>`
                formaterstr = inner0
                // 这里去掉了 title，只保留了 series 名称和数据的描述
                return formaterstr;
            }
            option.series[0].data = data
            myChart = this.$echarts.init(document.getElementById('schoolsChart_2'));
            myChart.setOption(option)


            data = this._lo.map(resData, 'my_percent')
            option.title.text = ""
            option.series[0].data = data
            myChart = this.$echarts.init(document.getElementById('schoolsChart_3'));
            myChart.setOption(option)

            data = this._lo.map(resData, 'qht_percent')
            option.title.text = ""
            option.series[0].data = data
            myChart = this.$echarts.init(document.getElementById('schoolsChart_4'));
            myChart.setOption(option)

            data = this._lo.map(resData, 'avg_salary')
            option.title.text = ""
            option.tooltip.formatter = function (params) {
                let formaterstr = '', inner0 = ''
                inner0 = `<span>${params[0].value}元</span>`
                formaterstr = inner0
                // 这里去掉了 title，只保留了 series 名称和数据的描述
                return formaterstr;
            }
            option.yAxis.name = '数值(元)'
            option.yAxis.max = this._lo.max(data)
            option.yAxis.min = this._lo.min(data)
            option.yAxis.interval = 1000
            option.series[0].data = data
            myChart = this.$echarts.init(document.getElementById('schoolsChart_5'));
            myChart.setOption(option)


            dataAxis = this._lo.map(majors, 'type_name')
            data = this._lo.map(majors, 'avg_salary')
            option.title.text = ""
            option.xAxis.data = dataAxis
            option.yAxis.name = '数值(元)'
            option.yAxis.max = this._lo.max(data)
            option.yAxis.min = this._lo.min(data)
            option.yAxis.interval = 1000
            option.series[0].data = data
            myChart = this.$echarts.init(document.getElementById('schoolsChart_6'));
            myChart.setOption(option)
        },
    },
    beforeDestroy: function () {

    }

};
</script>

<style scope lang="less" type="text/less">
.conbox {
    display: flex;
    /*color: #fff;
    padding: 0 50px;*/
}



.boxinner {
    padding: 30px 12px;
}

.boxtitle {
    height: 30px;
    padding-left: 10px;
    /*margin-bottom: 10px;
    background-image: linear-gradient(90deg, rgba(0, 160, 233, .3176470588235294), rgba(0, 160, 233, 0));*/
    line-height: 30px;
    font-size: 20px;
    font-weight: 700
}

.title2 {
    background-image: linear-gradient(270deg, rgba(0, 160, 233, .3176470588235294), rgba(0, 160, 233, 0));

}

.chart {
    width: 50%;
    height: 300px;
}
</style>
