<template>
	<div class="page" :style="{ backgroundImage: 'url(./img/bg5.png)' }">
		<div class="flexCloumn" style="width:100%;height: 100%; overflow:hidden;">
			<topbar></topbar>
			<div style="display:block;width:100%;height:1px;background:#fff;margin-top:20px;"></div>
			<div class="flexStretch" style="width:100%;height:calc(100% - 151px)">
				<div class="flexCloumnSingleCenter" style="width:86px;height:100%; justify-content:center;">
					<div :class="(active == 1) ? 'flexCloumnSingleCenter tabItem activebg' : 'flexCloumnSingleCenter tabItem normalbg'"
						@click="() => { active = 1; scrollToAnchor('anchor_school')}">
						<img v-show="active != 1" src="/img/home_tab_1_0.png" alt="">
						<img v-show="active == 1" src="/img/home_tab_1_1.png" alt="">
						<div :class="(active == 1) ? 'activeColor' :'normalColor'">学校</div>
					</div>
					<div style="width:1px;height:100px;border-right:1px dashed #5975FF;"></div>
					<div :class="(active == 2) ? 'flexCloumnSingleCenter tabItem activebg' : 'flexCloumnSingleCenter tabItem normalbg'"
						@click="() => { active = 2; scrollToAnchor('anchor_major') }">
						<img v-show="active != 2" src="/img/home_tab_2_0.png" alt="">
						<img v-show="active == 2" src="/img/home_tab_2_1.png" alt="">
						<div :class="(active == 2) ? 'activeColor' : 'normalColor'">专业</div>
					</div>
					<div style="width:1px;height:100px;border-right:1px dashed #5975FF;"></div>
					<div :class="(active == 3) ? 'flexCloumnSingleCenter tabItem activebg' : 'flexCloumnSingleCenter tabItem normalbg'"
						@click="() => { active = 3; scrollToAnchor('anchor_city') }">
						<img v-show="active != 3" src="/img/home_tab_3_0.png" alt="">
						<img v-show="active == 3" src="/img/home_tab_3_1.png" alt="">
						<div :class="(active == 3) ? 'activeColor' : 'normalColor'">全市</div>
					</div>
				</div>
				<div style="width:1px;height:100%;background:#fff;"></div>
				<div class="" style="width:calc(100% - 87px); height:100%; overflow-y: auto;">
					<concatChart :tPmid="0"></concatChart>
					<!-- <dtcon></dtcon>
					<diaochaChart :tPmid="0"></diaochaChart> -->
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import topbar from "./com/TopBar.vue"
import concatChart from "./com/concat.vue"

import dtcon from "./com/dtcon.vue"
import diaochaChart from './com/diaochaChart.vue'
export default {
	components: {
		topbar, dtcon, diaochaChart,concatChart
	},
	data() {
		return {
			active: 1,
		}
	},
	mounted() {
		if (this.$store.state.pmid && this.$store.state.pmid != 1) {

			this.$router.push("/jiuyebzr")
		}

	},
	methods: {
		// 使用平滑滚动到达指定的锚点
 scrollToAnchor(anchorName) {
    // 获取带有该名称的元素
    var anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
        // 使用平滑滚动到达该元素
        anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
}
 

	}
}
</script>
<style scoped>
.page {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #fff;

}
.tabItem{
width: 60px;
	background-color: #5975FF;
	border-radius: 5px;
	padding: 20px 0;
	cursor: pointer;
	font-weight:700;
}
.activebg{background-color: #5975FF;}
.normalbg {
	background-color: #fff;
}.tabItem .activeColor{color: #fff;margin-top: 10px;}
.tabItem .normalColor {
	color: #6055BB;
	margin-top:10px;
}
</style>
