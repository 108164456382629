<template>
  <div class="conbox flexCloumn" style="padding: 20px">
    <div id="anchor_school" style="width: 100%; height: 1px"></div>
    <div class="conbox" style="width: 100%">
      <div style="width: 50%; background-color: #fff; border-radius: 4px">
        <!-- <dv-border-box-13 style="height: auto;" :reverse="true"> -->
        <div class="boxinner flexStart" style="">
          <img src="/img/home_title_icon.png" alt="" />
          <div class="boxtitle">
            各个学校就业率统计图
            <el-tag class="ml10 pointer" size="small" plain @click="exportChart('chart_sch_ever_jyrate', '各个学校就业率统计图')">
              <i class="el-icon-download"></i>
              下载图表</el-tag
            >
          </div>
        </div>
        <div id="chart_sch_ever_jyrate" style="width: 95%; height: 690px"></div>
        <!-- </dv-border-box-13> -->
      </div>

      <div class="ml20" style="width: calc(50% - 20px); background-color: #fff; border-radius: 4px">
        <!-- <dv-border-box-13 style="height: auto;" :reverse="true"> -->
        <div class="boxinner flexStart" style="">
          <img src="/img/home_title_icon.png" alt="" />
          <div class="boxtitle">
            学生就业人数统计
            <el-tag class="ml10 pointer" size="small" plain @click="exportChart('chart_sch', '学生就业人数统计')">
              <i class="el-icon-download"></i>
              下载图表</el-tag
            >
          </div>
        </div>

        <!-- <div class="boxinner" style="padding-bottom: 0;">
                    <div class="boxtitle">学生就业人数统计</div>
                </div> -->
        <div id="chart_sch" style="width: 95%; height: 690px"></div>

        <!-- </dv-border-box-13> -->
      </div>
    </div>

    <!-- <div class="conbox mt20" style="width:100%">
            <div style="width: 50%;background-color:#fff;border-radius:4px;">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="">
                    <div class="boxtitle">企业类型对比图</div>
                </div>
                <div id="chart_2" style="height: 400px;width: 100%;"></div>
            </div>
        </div> -->

    <div class="conbox mt20" style="width: 100%; flex-wrap: wrap">
      <!-- <div style="width: 100%;">
                <div id="schoolsChart_1" style="height: 500px;width: 100%;"></div>
            </div> -->
      <div style="width: 100%; background-color: #fff; border-radius: 4px">
        <div class="boxinner flexStart" style="">
          <img src="/img/home_title_icon.png" alt="" />
          <div class="boxtitle">
            各个学校对口就业率统计图
            <el-tag class="ml10 pointer" size="small" plain @click="exportChart('schoolsChart_2', '各个学校对口就业率统计图')">
              <i class="el-icon-download"></i>
              下载图表</el-tag
            >
          </div>
        </div>
        <div id="schoolsChart_2" style="height: 500px; width: 95%"></div>
      </div>
      <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
        <div class="boxinner flexStart" style="">
          <img src="/img/home_title_icon.png" alt="" />
          <div class="boxtitle">
            各个学校毕业生满意度统计图
            <el-tag class="ml10 pointer" size="small" plain @click="exportChart('schoolsChart_3', '各个学校毕业生满意度统计图')">
              <i class="el-icon-download"></i>
              下载图表</el-tag
            >
          </div>
        </div>
        <div id="schoolsChart_3" style="height: 500px; width: 95%"></div>
      </div>
      <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
        <div class="boxinner flexStart" style="">
          <img src="/img/home_title_icon.png" alt="" />
          <div class="boxtitle">
            各学校合同签订情况
            <el-tag class="ml10 pointer" size="small" plain @click="exportChart('schoolsChart_4', '各学校合同签订情况')">
              <i class="el-icon-download"></i>
              下载图表</el-tag
            >
          </div>
        </div>
        <div id="schoolsChart_4" style="height: 500px; width: 95%"></div>
      </div>
      <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
        <div class="boxinner flexStart" style="">
          <img src="/img/home_title_icon.png" alt="" />
          <div class="boxtitle">
            各个学校的薪资对比图
            <el-tag class="ml10 pointer" size="small" plain @click="exportChart('schoolsChart_5', '各个学校的薪资对比图')">
              <i class="el-icon-download"></i>
              下载图表</el-tag
            >
          </div>
        </div>
        <div id="schoolsChart_5" style="height: 500px; width: 95%"></div>
      </div>
    </div>

    <div id="anchor_major" style="width: 100%; height: 1px"></div>
    <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
      <div class="boxinner flexStart" style="">
        <img src="/img/home_title_icon.png" alt="" />
        <div class="boxtitle">
          各专业大类就业率统计表
          <el-tag class="ml10 pointer" size="small" plain @click="exportChart('yjChart30', '各专业大类就业率统计表')">
            <i class="el-icon-download"></i>
            下载图表</el-tag
          >
        </div>
      </div>
      <div id="yjChart30" style="width: 95%; height: 400px"></div>
    </div>
    <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
      <div class="boxinner flexStart" style="">
        <img src="/img/home_title_icon.png" alt="" />
        <div class="boxtitle">
          各专业大类对口率统计表
          <el-tag class="ml10 pointer" size="small" plain @click="exportChart('yjChart31', '各专业大类对口率统计表')">
            <i class="el-icon-download"></i>
            下载图表</el-tag
          >
        </div>
      </div>
      <div id="yjChart31" style="width: 95%; height: 400px"></div>
    </div>
    <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
      <div class="boxinner flexStart" style="">
        <img src="/img/home_title_icon.png" alt="" />
        <div class="boxtitle">
          各专业大类的薪资平均值对比图
          <el-tag class="ml10 pointer" size="small" plain @click="exportChart('schoolsChart_6', '各专业大类的薪资平均值对比图')">
            <i class="el-icon-download"></i>
            下载图表</el-tag
          >
        </div>
      </div>
      <div id="schoolsChart_6" style="height: 500px; width: 95%"></div>
    </div>

    <div id="anchor_city" style="width: 100%; height: 1px"></div>
    <div class="conbox flexCloumn mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
      <div class="boxinner flexStart" style="width: 100%">
        <img src="/img/home_title_icon.png" alt="" />
        <div class="boxtitle">
          南京市中等职业学校就业情况分布图
          <el-tag class="ml10 pointer" size="small" plain @click="exportTable('myPieCharts', '南京市中等职业学校就业情况分布图')">
            <i class="el-icon-download"></i>
            下载图表</el-tag
          >
        </div>
      </div>
      <div id="myPieCharts" style="width: 100%; display: flex; flex-flow: row wrap; justify-content: space-around; margin-top: 30px">
        <div class="chartPie"><div id="yjChart1" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie"><div id="yjChart2" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie"><div id="yjChart21" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie"><div id="yjChart3" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie"><div id="yjChart4" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie"><div id="yjChart5" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie" style="width: 25%"><div id="yjChart6" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie" style="width: 25%"><div id="yjChart7" style="width: 95%; height: 100%"></div></div>
        <!-- <div class="chartPie"></div> -->
        <div class="chartPie" style="width: 25%"><div id="yjChart8" style="width: 95%; height: 100%"></div></div>
        <div class="chartPie" style="width: 25%"><div id="yjChart20" style="width: 95%; height: 100%"></div></div>
        <!-- <div class="chartPie"></div> -->
      </div>
    </div>
    <div class="mt20" style="padding: 0 50px 50px 50px; width: 100%; background-color: #fff; border-radius: 4px">
      <div class="boxinner flexStart" style="width: calc(100% + 50px); margin-left: -50px">
        <img src="/img/home_title_icon.png" alt="" />
        <div class="boxtitle flexBetween flex_1">
          <div>
            南京市中等职业学校专业大类毕业生人数统计表
            <el-tag class="ml10 pointer" size="small" plain @click="exportTable('myexporttable', '南京市中等职业学校专业大类毕业生人数统计表')">
              <i class="el-icon-download"></i>
              下载图表</el-tag
            >
          </div>
          <!-- <i class="el-icon-download" style="cursor: pointer" @click="exportTable"></i> -->
        </div>
      </div>

      <table id="myexporttable" class="dttable" v-if="MajorTongji.length > 0">
        <tr>
          <td style="width: 4.96%; color: #333; font-weight: 700">学校名称</td>
          <td style="width: 4.75%; color: #333; font-weight: 700" v-for="(mj, i) in MajorTongji[0].major_type" :key="i">
            <span :class="mj.name == '合计' ? 'color_hj' : ''">{{ mj.name }}</span>
          </td>
        </tr>
        <tr v-for="(sch, i) in MajorTongji" :key="'sch_' + i">
          <td :class="sch.platform == '合计' ? 'color_hj' : ''" style="color: #333; font-weight: 700">{{ sch.platform }}</td>
          <td style="color: #333" v-for="(mj, mi) in sch.major_type" :key="'major_' + mi">
            <span :class="sch.name == '合计' || mi == sch.major_type.length - 1 || i == MajorTongji.length - 1 ? 'color_hj' : ''">{{ mj.data ? mj.data.bys_num : "" }}</span>
          </td>
        </tr>
      </table>
    </div>

    <div style="height: 20px; color: #fff"></div>
  </div>
</template>

<script>
import echartMixins from "../../js/resizeMixins";
let colors = require("./color.js");
import html2canvas from "html2canvas";

export default {
  props: ["tPmid"],

  data() {
    return {
      Tongji: {
        data: [],
        colors: ["#e062ae", "#fb7293", "#e690d1", "#32c5e9", "#96bfff"],
        unit: "企业数",
        showValue: true,
      },
      DataInfo: null,
      xuezhiList: [],
      xuezhiName: "",
      MajorTongji: [],
      enterTypeList: {},
    };
  },
  mixins: [echartMixins],

  mounted() {
    this.getEnterData();
    this.getTongji();

    this.getData();
  },
  methods: {
    exportChart(chartName, downloadName) {
      // let myChart = this.$echarts.getInstanceByDom(this.$refs.myChart);
      let myChart = this.$echarts.getInstanceByDom(document.getElementById(chartName));
      // 获取画布图表地址信息
      const imgUrl = myChart.getDataURL({
        type: "jpeg",
        pixelRatio: 1,
        backgroundColor: "#fff",
        excludeComponents: [
          //保存图表时忽略的工具组件,默认忽略工具栏
          "toolbox",
        ],
      });
      // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
      if (window.navigator.msSaveOrOpenBlob) {
        // 截取base64的数据内容（去掉前面的描述信息，类似这样的一段：data:image/png;base64,）并解码为2进制数据
        let bstr = atob(imgUrl.split(",")[1]);
        // 获取解码后的二进制数据的长度，用于后面创建二进制数据容器
        let n = bstr.length;
        // 创建一个Uint8Array类型的数组以存放二进制数据
        let u8arr = new Uint8Array(n);
        // 将二进制数据存入Uint8Array类型的数组中
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        // 创建blob对象
        let blob = new Blob([u8arr]);
        // 调用浏览器的方法，调起IE的下载流程
        window.navigator.msSaveOrOpenBlob(blob, downloadName + ".jpg");
      } else {
        //类似chrome浏览器创建一个a标签并使用a标签的href属性下载
        let tempA = document.createElement("a");
        tempA.download = downloadName + ".jpg";
        tempA.href = imgUrl;
        document.body.appendChild(tempA);
        tempA.click();
        tempA.remove();

        // var url = myChart.getConnectedDataURL({
        //   pixelRatio: 5, //导出的图片分辨率比率,默认是1
        //   backgroundColor: '#fff', //图表背景色
        //   excludeComponents: [ //保存图表时忽略的工具组件,默认忽略工具栏
        //     'toolbox'
        //   ],
        //   type: 'png' //图片类型支持png和jpeg
        // });
      }
    },

    exportTable(chartName, downloadName) {
      html2canvas(document.getElementById(chartName), {
        backgroundColor: "white",
        useCORS: true, //支持图片跨域
        scale: 1, //设置放大的倍数
        height: document.getElementById(chartName).scrollHeight,
        windowHeight: document.getElementById(chartName).scrollHeight,
      }).then((canvas) => {
        // 生成图片导出
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = downloadName;
        a.click();
      });
    },
    getEnterData() {
      let data = {
        page: 1,
        keyword: "",
        enter_type: "",
        enter_hy: "",
        school_id: "",
        showloading: false,
        tjtype: "",
      };
      this.$http.post("/api/jyqx_tongji", data).then((res) => {
        let arr = [];
        for (let item of res.data) {
          arr.push({ name: item.jyqx, value: item.nums });
        }
        arr = arr.sort((a, b) => {
          return b.value - a.value;
        });
        this.Tongji.data = arr;
      });
    },
    getTongji() {
      this.$http.post("/api/enter_chart_tongji", { tpmid: this.schoolId }).then((res) => {
        this.DataList = res.data;
        setTimeout(() => {
          this.initColumn();
        }, 500);

        this.getSchData();
      });
    },
    getSchData() {
      let _this = this;
      this.$http.post("/api/get_sch_multicount").then((res) => {
        let dkjy_percent_avg = 0,
          dkcount_total = 0,
          my_percent_avg = 0,
          my_count_total = 0,
          qht_percent_avg = 0,
          qht_count_total = 0,
          wqht_count_total = 0,
          avg_salary_avg = 0,
          total_salary_total = 0,
          total_salary_stunum_total = 0;
        let jy_percent_avg = 0,
          bycount_total = 0,
          jycount_total = 0;

        res.data.schools.map((a) => {
          //   console.log(121212, a);
          if (a.dkjy_percent) dkjy_percent_avg += a.dkjy_percent;
          if (a.dkcount) dkcount_total += a.dkcount;

          if (a.my_percent) my_percent_avg += a.my_percent;
          if (a.my_count) my_count_total += a.my_count;

          if (a.qht_percent) qht_percent_avg += a.qht_percent;
          if (a.qht_count) qht_count_total += a.qht_count;
          if (a.wqht_count) wqht_count_total += a.wqht_count;

          if (a.avg_salary) avg_salary_avg += a.avg_salary;
          if (a.total_salary) total_salary_total += a.total_salary;
          if (a.total_salary_stunum) total_salary_stunum_total += a.total_salary_stunum;

          if (a.jy_percent) jy_percent_avg += a.jy_percent;
          if (a.bycount) bycount_total += a.bycount;
          if (a.jycount) jycount_total += a.jycount;
        });
        let arr = JSON.parse(JSON.stringify(res.data.schools));
        let arr1 = JSON.parse(JSON.stringify(res.data.schools));
        let arr_1 = JSON.parse(JSON.stringify(res.data.schools));
        let arr_2 = JSON.parse(JSON.stringify(res.data.schools));
        let arr_3 = JSON.parse(JSON.stringify(res.data.schools));
        let arr_4 = JSON.parse(JSON.stringify(res.data.schools));
        let len = 14;
        let obj = JSON.parse(JSON.stringify(res.data.schools[0]));
        obj.platform = "南京市平均值";
        obj.dkjy_percent = ((dkcount_total / jycount_total) * 10000) / 100;
        obj.dkjy_percent = Math.floor(obj.dkjy_percent * 100) / 100;

        obj.my_percent = ((my_count_total / bycount_total) * 10000) / 100;
        obj.my_percent = Math.floor(obj.my_percent * 100) / 100;

        // obj.qht_percent = ((dkcount_total - wqht_count_total ) / (dkcount_total)*10000/100);
        obj.qht_percent = qht_percent_avg / len;
        obj.qht_percent = Math.floor(obj.qht_percent * 100) / 100;

        // obj.avg_salary = (avg_salary_avg / len);
        obj.avg_salary = parseInt(total_salary_total / total_salary_stunum_total);
        // obj.avg_salary = Math.floor(obj.avg_salary * 100) / 100;

        obj.jy_percent = ((jycount_total / bycount_total) * 10000) / 100;
        obj.jy_percent = Math.floor(obj.jy_percent * 100) / 100;

        arr.unshift(obj);

        arr1 = arr1.sort((a, b) => {
          return b.jy_percent - a.jy_percent;
        });
        arr1.unshift(obj);
        // setTimeout(() => {
        _this.initchart_sch_ever_jyrate(arr1);

        arr_1 = arr_1.sort((a, b) => {
          return b.dkjy_percent - a.dkjy_percent;
        });
        arr_2 = arr_2.sort((a, b) => {
          return b.my_percent - a.my_percent;
        });
        arr_3 = arr_3.sort((a, b) => {
          return b.qht_percent - a.qht_percent;
        });
        arr_4 = arr_4.sort((a, b) => {
          return b.avg_salary - a.avg_salary;
        });
        arr_1.unshift(obj);
        arr_2.unshift(obj);
        arr_3.unshift(obj);
        arr_4.unshift(obj);
        let majors = JSON.parse(JSON.stringify(res.data.majorTypes));
        majors = this._lo.filter(majors, this._lo.negate(this._lo.matches({ avg_salary: 0 })));
        majors = majors.sort((a, b) => {
          return b.avg_salary - a.avg_salary;
        });

        _this.initEverSchools(arr, majors, arr_1, arr_2, arr_3, arr_4);
        // }, 1000);
      });

      this.$http.post("/api/get_sch_stucount").then((res) => {
        this.initSchColumn(res.data);
      });
    },

    initColumn() {
      let xData = [];
      let xValue = [];

      let option = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "white",
          },
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {
              title: "下载图片", //鼠标滑过之后文案
              name: "我的图表", //图表名称
            },
          },
        },
        grid: {
          top: "5%",
          bottom: "10%",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        color: colors,
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            // color: 'white',
            rotate: 45, // 旋转角度为45度
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // color: 'white'
          },
          splitLine: { show: false },
        },
        series: [
          {
            name: "就业人数",
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
            barMaxWidth: 20,
            showBackground: false,
            itemStyle: {
              color: function (params) {
                return colors[params.dataIndex];
              },
              borderRadius: [10, 10, 0, 0],
              shadowBlur: 1, // 设置阴影模糊度
              shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
            },
            label: {
              show: true,
              position: "top",
              fontSize: 10,
            },
          },
        ],
      };
      //城市
      let getPropValue = (arr, name, code) => {
        let value = 0;
        for (let item of arr) {
          if (item[code] == name) {
            value = item.n;
            break;
          }
        }
        return value;
      };
      let areas = this.DataList.areas;
      /*for (let item of areas) {
                if (item.cname == "市辖区") {
                    item.cname = item.province
                }
            }*/
      option.xAxis.data = areas.map((e) => {
        return e.name;
      });
      option.series[0].data = this.DataList.areas.map((e) => {
        return e.num;
      });
      option.title.text = "企业所在城市对比图";
      option.grid.bottom = 200;
      // this.$echarts.init(document.getElementById('chart_1')).setOption(option);

      option.grid.bottom = 100;
      let enterType = this.DataList.enterType;
      for (let item of enterType) {
        if (!item.enter_type) {
          item.enter_type = "未知类型";
        }
      }
      option.xAxis.data = enterType.map((e) => {
        return e.enter_type;
      });
      option.series[0].data = enterType.map((e) => {
        return e.n;
      });
      option.title.text = "";
      //option.xAxis.axisLabel.rotate = 90
      //   this.$echarts.init(document.getElementById("chart_2")).setOption(option);

      option.xAxis.axisLabel.rotate = 45;
      option.grid.bottom = 90;
      let enter_hy = this.DataList.enter_hy;
      for (let item of enter_hy) {
        if (!item.enter_hy) {
          item.enter_hy = "未知行业";
        }
      }
      option.xAxis.data = enter_hy.map((e) => {
        return e.enter_hy;
      });
      option.series[0].data = enter_hy.map((e) => {
        return e.n;
      });
      option.title.text = "企业行业对比图";

      // this.$echarts.init(document.getElementById('chart_3')).setOption(option);

      let guimo = this.DataList.guimo;
      for (let item of guimo) {
        if (!item.guimo) {
          item.guimo = "未知";
        }
        if (item.guimo == "-") {
          item.guimo = "个体户";
        }
      }
      option.xAxis.data = guimo.map((e) => {
        return e.guimo;
      });
      option.series[0].data = guimo.map((e) => {
        return e.n;
      });
      option.title.text = "企业规模对比图";

      // this.$echarts.init(document.getElementById('chart_4')).setOption(option);
    },

    initSchColumn(data) {
      let resData = JSON.parse(JSON.stringify(data));
      resData = resData.reverse();

      let reverseColor = JSON.parse(JSON.stringify(colors));
      reverseColor = reverseColor.reverse();
      let xData = resData.map((e) => {
        return e.platform;
      });
      console.log(xData);
      let xValue = [];
      let option = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "white",
          },
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {
              title: "下载图片", //鼠标滑过之后文案
              name: "我的图表", //图表名称
            },
          },
        },
        grid: {
          left: 190,
          top: 0,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          title: {
            show: false,
          },
          formatter: function (params) {
            let index = params[0].dataIndex;
            let info = resData[index];
            // console.log(279, info)

            let formaterstr = "",
              inner0 = "",
              inner1 = "",
              inner2 = "";
            let p0 = undefined;
            let p1 = undefined;
            let p2 = undefined;
            inner0 = ` <span>毕业生总数:${info.bycount}</span>
                                <br/>`;
            inner1 = ` <span>就业人数:${info.jycount}</span>
                                <br/>`;
            inner2 = ` <span>待业人数:${info.dycount}</span>
                                <br/>`;
            formaterstr = inner0 + inner1 + inner2;
            // 这里去掉了 title，只保留了 series 名称和数据的描述
            return formaterstr;
          },
        },
        xAxis: {
          type: "value",
          data: resData.map((e) => {
            return e.platform;
          }),
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            // color: 'white'
          },
          splitLine: { show: false },
        },
        yAxis: {
          data: xData,
          type: "category",
          axisLabel: {
            // color: 'white'
            textStyle: {
              color: "#000", // 设置 x 轴文字颜色
              fontWeight: "bold",
            },
          },
          splitLine: { show: false },
        },
        series: [
          // {
          //     name: "毕业生总数",
          //     data: resData.map(e => { return e.bycount }),
          //     type: 'bar',
          //     barMaxWidth: 20,
          //     showBackground: false,
          //     itemStyle: {
          //         color: function (params) {

          //             return colors[params.dataIndex];
          //         },
          //         borderRadius: [10, 10, 0, 0],
          //         shadowBlur: 1, // 设置阴影模糊度
          //         shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
          //     },
          //     label: {
          //         show: true,
          //         position: 'right',
          //         fontSize: 10
          //     }
          // },
          {
            name: "就业人数",
            data: resData.map((e) => {
              return e.jycount;
            }),
            type: "bar",
            barMaxWidth: 20,
            showBackground: false,
            itemStyle: {
              color: function (params) {
                return reverseColor[params.dataIndex];
              },
              borderRadius: [10, 10, 0, 0],
              shadowBlur: 1, // 设置阴影模糊度
              shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
            },
            // label: {
            //     show: true,
            //     position: 'right',
            //     fontSize: 10
            // }
            label: {
              show: true,
              position: "inside",
              formatter: function (params) {
                //标签内容
                return params.value + "人";
              },
            },
          },
          // {
          //     name: "待业人数",
          //     data: resData.map(e => { return e.dycount }),
          //     type: 'bar',
          //     barMaxWidth: 20,
          //     showBackground: false,
          //     itemStyle: {
          //         color: function (params) {

          //             return colors[params.dataIndex];
          //         },
          //         borderRadius: [10, 10, 0, 0],
          //         shadowBlur: 1, // 设置阴影模糊度
          //         shadowColor: 'rgba(255, 255, 255, 0.5)' // 设置阴影颜色
          //     },
          //     label: {
          //         show: true,
          //         position: 'right',
          //         fontSize: 10
          //     }
          // }
        ],
      };
      option.toolbox.feature.saveAsImage.name = "学生就业人数统计";
      this.$echarts.init(document.getElementById("chart_sch")).setOption(option);
      let jycount = 0,
        sxcount = 0,
        bycount = 0,
        dycount = 0;
      for (let item of resData) {
        jycount += item.jycount;
        sxcount += item.sxcount;
        bycount += item.bycount;
        dycount += item.dycount;
      }
      $("#jycount").text(jycount);
      $("#sxcount").text(sxcount);
      $("#bycount").text(bycount);
      $("#dycount").text(dycount);
      console.log(441, dycount);
    },

    initchart_sch_ever_jyrate(data) {
      let resData = JSON.parse(JSON.stringify(data));
      resData[0].platform = "南京市平均就业率";

      resData = resData.reverse();
      let reverseColor = JSON.parse(JSON.stringify(colors));
      reverseColor = reverseColor.reverse();
      let xData = resData.map((e) => {
        return e.platform;
      });
      // console.log(xData)
      let xValue = [];
      let option = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "white",
          },
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {
              title: "下载图片", //鼠标滑过之后文案
              name: "我的图表", //图表名称
            },
          },
        },
        grid: {
          left: 190,
          top: 0,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          title: {
            show: false,
          },
          formatter: function (params) {
            let index = params[0].dataIndex;
            let info = resData[index];
            // console.log(279, info)

            let formaterstr = "",
              inner0 = "",
              inner1 = "",
              inner2 = "";
            let p0 = undefined;
            let p1 = undefined;
            let p2 = undefined;
            inner0 = ` <span>就业率:${info.jy_percent}%</span>
                                <br/>`;
            inner1 = ` <span>就业人数:${info.jycount}</span>
                                <br/>`;
            inner2 = ` <span>待业人数:${info.dycount}</span>
                                <br/>`;
            formaterstr = inner0;
            // 这里去掉了 title，只保留了 series 名称和数据的描述
            return formaterstr;
          },
        },
        xAxis: {
          type: "value",
          data: resData.map((e) => {
            return e.platform;
          }),
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {},
          splitLine: { show: false },
        },
        yAxis: {
          data: xData,
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#000", // 设置 x 轴文字颜色
              fontWeight: "bold",
            },
            formatter: function (value, index) {
              // 根据 value 或 index 自定义标签样式
              if (value == "南京市平均就业率") {
                return "{a|南京市平均}" + "{b|就业率}";
              }
              return value;
            },
            rich: {
              a: {
                color: "#f56c6c",
                // fontSize: 16,
                fontWeight: "bold",
              },
              b: {
                color: "#f56c6c",
                // fontSize: 16,
                fontWeight: "bold",
              },
            },
          },
          splitLine: { show: false },
        },
        series: [
          {
            name: "就业率",
            data: resData.map((e) => {
              return e.jy_percent;
            }),
            type: "bar",
            barMaxWidth: 20,
            showBackground: false,
            label: {
              show: true,
              position: "inside",
              formatter: function (params) {
                //标签内容
                return params.value + "%";
              },
            },
            itemStyle: {
              color: function (params) {
                if (params.name == "南京市平均就业率") {
                  return "#f56c6c";
                }
                return reverseColor[params.dataIndex];
              },
              borderRadius: [10, 10, 0, 0],
              shadowBlur: 1, // 设置阴影模糊度
              shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
            },
          },
        ],
      };
      option.toolbox.feature.saveAsImage.name = "各个学校就业率统计图";
      this.$echarts.init(document.getElementById("chart_sch_ever_jyrate")).setOption(option);
    },
    initEverSchools(resData, majors, arr_1, arr_2, arr_3, arr_4) {
      let dataAxis = this._lo.map(resData, "platform");
      let data = this._lo.map(resData, "jy_percent");

      let option = {
        title: {
          text: "各个学校就业率统计图",
          left: "center",
          textStyle: {
            color: "white",
          },
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {
              title: "下载图片", //鼠标滑过之后文案
              name: "我的图表", //图表名称
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "5%",
          bottom: "30%",
        },
        xAxis: {
          type: "category",
          data: dataAxis,
          axisPointer: {
            type: "shadow",
          },
          itemStyle: {
            color: function (params) {
              return colors[params.dataIndex];
            },
            borderRadius: [10, 10, 0, 0],
            shadowBlur: 1, // 设置阴影模糊度
            shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
          },
          axisLabel: {
            textStyle: {
              color: "#000", // 设置 x 轴文字颜色
              fontWeight: "bold",
            },
            rotate: 45, // 旋转角度为45度
            formatter: function (value, index) {
              // 根据 value 或 index 自定义标签样式
              if (value == "南京市平均对口就业率") {
                return "{a|南京市平均}" + "{b|对口就业率}";
              }
              if (value == "南京市平均就业满意度") {
                return "{a|南京市平均}" + "{b|就业满意度}";
              }
              if (value == "南京市平均合同签订率") {
                return "{a|南京市平均}" + "{b|合同签订率}";
              }
              if (value == "南京市平均薪资") {
                return "{a|南京市}" + "{b|平均薪资}";
              }
              if (value == "平均薪资") {
                return "{a|平均}" + "{b|薪资}";
              }
              return value;
            },
            rich: {
              a: {
                color: "#f56c6c",
                // fontSize: 16,
                fontWeight: "bold",
              },
              b: {
                color: "#f56c6c",
                // fontSize: 16,
                fontWeight: "bold",
              },
            },
          },
        },
        yAxis: {
          type: "value",
          name: "百分比",
          splitLine: { show: false },
          // axisLabel: { color: '#fff' },
        },
        color: ["#6055BB", "#2469f3", "#cccc99", "#ff6666", "#cccc00", "#996699", "#333333"],
        series: [
          {
            type: "bar",
            barMaxWidth: 40,

            data: data,
            label: {
              show: true,
              position: "inside",
              // formatter: function (params) { //标签内容
              //   return params.value + '%'
              // },
            },
            itemStyle: {
              color: function (params) {
                if (params.name == "南京市平均对口就业率" || params.name == "南京市平均就业满意度" || params.name == "南京市平均合同签订率" || params.name == "南京市平均薪资" || params.name == '平均薪资') {
                  return "#f56c6c";
                }
              },
            },
          },
        ],
      };
      let myChart = null;
      // let myChart = this.$echarts.init(document.getElementById('schoolsChart_1'));
      // myChart.setOption(option)

      dataAxis = this._lo.map(arr_1, "platform");
      data = this._lo.map(arr_1, "dkjy_percent");
      option.title.text = "";
      option.grid.top = 50;
      option.tooltip.formatter = function (params) {
        let formaterstr = "",
          inner0 = "";
        inner0 = `<span>${params[0].value}%</span>`;
        formaterstr = inner0;
        // 这里去掉了 title，只保留了 series 名称和数据的描述
        return formaterstr;
      };
      dataAxis[0] = "南京市平均对口就业率";
      option.xAxis.data = dataAxis;
      option.series[0].data = data;
      option.toolbox.feature.saveAsImage.name = "各个学校对口就业率统计图";
      myChart = this.$echarts.init(document.getElementById("schoolsChart_2"));
      myChart.setOption(option);

      dataAxis = this._lo.map(arr_2, "platform");
      dataAxis[0] = "南京市平均就业满意度";
      option.xAxis.data = dataAxis;
      data = this._lo.map(arr_2, "my_percent");
      option.title.text = "";
      option.series[0].data = data;
      option.toolbox.feature.saveAsImage.name = "各个学校毕业生满意度统计图";
      myChart = this.$echarts.init(document.getElementById("schoolsChart_3"));
      myChart.setOption(option);

      dataAxis = this._lo.map(arr_3, "platform");
      dataAxis[0] = "南京市平均合同签订率";
      option.xAxis.data = dataAxis;
      data = this._lo.map(arr_3, "qht_percent");
      option.title.text = "";
      option.series[0].data = data;
      option.toolbox.feature.saveAsImage.name = "各学校合同签订情况";
      myChart = this.$echarts.init(document.getElementById("schoolsChart_4"));
      myChart.setOption(option);

      dataAxis = this._lo.map(arr_4, "platform");
      dataAxis[0] = "南京市平均薪资";
      option.xAxis.data = dataAxis;
      data = this._lo.map(arr_4, "avg_salary");
      option.title.text = "";
      option.tooltip.formatter = function (params) {
        let formaterstr = "",
          inner0 = "";
        inner0 = `<span>${params[0].value}元</span>`;
        formaterstr = inner0;
        // 这里去掉了 title，只保留了 series 名称和数据的描述
        return formaterstr;
      };
      option.yAxis.name = "薪资(元)";
      //   option.yAxis.max = this._lo.max(data);
      //   option.yAxis.min = 2490;
      option.yAxis.interval = null;
      option.series[0].data = data;
      option.toolbox.feature.saveAsImage.name = "各个学校的薪资对比图";
      myChart = this.$echarts.init(document.getElementById("schoolsChart_5"));
      myChart.setOption(option);

      dataAxis = this._lo.map(majors, "type_name");
      data = this._lo.map(majors, "avg_salary");
      dataAxis.unshift("平均薪资")
      data.unshift(Math.round(data.reduce((a,b) => a+b,0)/data.length))
      option.title.text = "";
      option.xAxis.data = dataAxis;
      option.yAxis.name = "薪资(元)";
      //   option.yAxis.max = this._lo.max(data);
      //   option.yAxis.min = this._lo.min(data);
      option.yAxis.interval = null;
      option.series[0].data = data;
      option.toolbox.feature.saveAsImage.name = "各专业大类的薪资平均值对比图";
      myChart = this.$echarts.init(document.getElementById("schoolsChart_6"));
      myChart.setOption(option);
    },
    getData() {
      const customOrder = ["农林牧渔大类", "资源环境与安全大类", "能源动力与材料大类", "土木建筑大类", "水利大类", "装备制造大类", "生物与化工大类", "轻工纺织大类", "食品药品与粮食大类", "交通运输大类", "电子与信息大类", "医药卫生大类", "财经商贸大类", "旅游大类", "文化艺术大类", "新闻传播大类", "教育与体育大类", "公安与司法大类", "公共管理与服务大类", "总计"];
      const orderMap = customOrder.reduce((map, name) => {
        map[name] = String(name).charCodeAt(0);
        return map;
      }, {});

      let data = { tpmid: "all", xuezhi: this.xuezhiName };
      this.$http.post("/api/get_jiuye_tjtable2", data).then((res) => {
        // if (res.data.majors_bys) {
        //   console.log(824,res.data.majors_bys)

        //   let  majors_bys = this._lo.orderBy(res.data.majors_bys, [(user) =>
        //   orderMap[user.major_type]], ["asc"]);
        //   res.data.majors_bys = majors_bys;
        // }

        this.DataInfo = res.data;
        this.xuezhiList = res.data.xuezhi.map((e) => {
          return e.class_xz;
        });
        setTimeout(() => {
          this.initPie();
          this.initColumn_2();
          this.initMcolumn();
        }, 1000);
        this.getMajorTongji();
      });
    },
    getMajorTongji() {
      this.$http.post("/api/major_type_tongji", { xuezhi: this.xuezhiName }).then((res) => {
        let arr = res.data;

        res.data.map((a) => {
          let mobj = JSON.parse(JSON.stringify(a.major_type[0]));
          // console.log(145, m.name, a)
          mobj.name = "合计";
          // 循环加专业合计
          let school_total = 0;
          a.major_type.map((mitem) => {
            if (mitem.data) {
              school_total += mitem.data.bys_num;
            }
          });
          if (!mobj.data) {
            mobj.data = {};
          }
          mobj.data.bys_num = school_total;
          a.major_type.push(mobj);
        });

        let obj = JSON.parse(JSON.stringify(res.data[0]));
        console.log(res.data);
        if (obj.major_type) {
          obj.major_type.map((m, index) => {
            let total_by = 0;
            res.data.map((a) => {
              // console.log(145, m.name, a)
              if (a.major_type[index].data) {
                total_by += a.major_type[index].data.bys_num;
              }
            });
            // console.log(150, m)
            if (!m.data) {
              m.data = {};
            }
            m.data.bys_num = total_by || "";
          });
        }
        obj.platform = "合计";
        arr.push(obj);

        this.MajorTongji = arr;
      });
    },
    getEnterTypePieTongji() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/enter_chart_tongji").then((res) => {
          this.enterTypeList = res.data;
          resolve();
        });
      });
    },
    initPie() {
      let option = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            // color: 'white'
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              title: "下载图片", //鼠标滑过之后文案
              name: "我的图表", //图表名称
            },
          },
        },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //     top: "10%",
        //     left: 'center'
        // },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              // borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              normal: {
                formatter: "{b}{c}人({d}%)",
                textStyle: {
                  fontWeight: "normal",
                  fontSize: 12,
                  color: "#000",
                },
              },
            },
            data: [],
            color: ["#78A3E0", "#6055BB", "#88C6BB", "#E9A65D", "#78A3E0", "#6055BB", "#2469f3", "#cccc99", "#ff6666", "#cccc00", "#996699", "#333333"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      //就业率
      let percent = parseInt((this.DataInfo.jiuye_num / this.DataInfo.bys_num) * 10000) / 100;
      let other_num = parseInt(this.DataInfo.bys_num) - parseInt(this.DataInfo.jiuye_num);
      let xdata = [
        { value: percent, name: "就业率" },
        { value: (100 - percent).toFixed(2), name: "待业" },
      ];
      option.series[0].name = "就业率";
      option.series[0].data = xdata;
      option.series[0].label.normal.formatter = "{b}{c}%";
      option.title.text = "就业率";
      option.toolbox.feature.saveAsImage.name = "就业率";
      this.$echarts.init(document.getElementById("yjChart1")).setOption(option);

      percent = parseInt((this.DataInfo.duikou_num / (this.DataInfo.bys_num - this.DataInfo.daiye_num)) * 10000) / 100;

      xdata = [
        { value: percent, name: "对口率" },
        { value: (100 - percent).toFixed(2), name: "不对口" },
      ];
      option.series[0].name = "对口就业率";
      option.series[0].data = xdata;
      option.title.text = "对口就业率";
      option.toolbox.feature.saveAsImage.name = "对口就业率";
      this.$echarts.init(document.getElementById("yjChart2")).setOption(option);

      //就业去向
      xdata = this.DataInfo.quxiang
        .filter((e) => !!e.quxiang)
        .map((e) => {
          return { value: e.n, name: e.quxiang };
        });
      option.title.text = "就业去向分组";
      option.series[0].name = "就业去向";
      option.series[0].data = xdata;
      option.series[0].label.normal.formatter = "{b}{c}人({d}%)";
      option.toolbox.feature.saveAsImage.name = "就业去向";
      this.$echarts.init(document.getElementById("yjChart3")).setOption(option);
      //就业产业
      xdata = this.DataInfo.chanye.map((e) => {
        if (e.chanye && e.chanye != "") {
          return { value: e.n, name: e.chanye };
        }
      });
      option.title.text = "就业产业分组";
      option.series[0].name = "就业产业";
      option.series[0].data = xdata;
      option.toolbox.feature.saveAsImage.name = "就业产业";
      this.$echarts.init(document.getElementById("yjChart4")).setOption(option);

      //就业地域
      xdata = this.DataInfo.diyu.map((e) => {
        if (e.diyu && e.diyu != "") {
          return { value: e.n, name: e.diyu };
        }
      });
      option.title.text = "就业地域分组";
      option.series[0].name = "就业地域";
      option.series[0].data = xdata;
      option.toolbox.feature.saveAsImage.name = "就业地域";
      this.$echarts.init(document.getElementById("yjChart5")).setOption(option);
      //就业地点
      xdata = this.DataInfo.didian.map((e) => {
        if (e.didian && e.didian != "") {
          return { value: e.n, name: e.didian };
        }
      });
      option.title.text = "就业地点分组";
      option.series[0].name = "就业地点";
      option.series[0].data = xdata;
      option.toolbox.feature.saveAsImage.name = "就业地点";
      this.$echarts.init(document.getElementById("yjChart6")).setOption(option);

      //就业渠道
      xdata = this.DataInfo.qudao.map((e) => {
        if (e.qudao && e.qudao != "") {
          return { value: e.n, name: e.qudao };
        }
      });
      option.title.text = "就业渠道分组";
      option.series[0].name = "就业渠道";
      option.series[0].data = xdata;
      option.toolbox.feature.saveAsImage.name = "就业渠道";
      this.$echarts.init(document.getElementById("yjChart7")).setOption(option);

      // //资格证书情况
      // xdata = this.DataInfo.zhengshu.map(e => { return { value: e.n, name: e.zhengshu } });
      // option.title.text = "资格证书情况"
      // option.series[0].name = "资格证书情况"
      // option.series[0].data = xdata;
      // this.$echarts.init(document.getElementById('yjChart8')).setOption(option);
      //满意度
      xdata = this.DataInfo.manyidu.map((e) => {
        return { value: e.n, name: e.manyidu };
      });
      option.title.text = "满意度情况";
      option.series[0].name = "满意度情况";
      option.series[0].data = xdata;
      option.toolbox.feature.saveAsImage.name = "满意度情况";
      this.$echarts.init(document.getElementById("yjChart8")).setOption(option);
      //保险情况
      xdata = this.DataInfo.baoxian.map((e) => {
        return { value: e.n, name: e.wuxian };
      });
      option.title.text = "保险情况";
      option.series[0].name = "保险情况";
      option.series[0].data = xdata;
      option.toolbox.feature.saveAsImage.name = "保险情况";
      this.$echarts.init(document.getElementById("yjChart20")).setOption(option);

      // this.$echarts.init(document.getElementById('schoolChart100')).setOption(option);

      this.getEnterTypePieTongji().then((_) => {
        console.log(341, this.enterTypeList);
        let enterType = this.enterTypeList.enterType;
        for (let item of enterType) {
          if (!item.enter_type) {
            item.enter_type = "未知类型";
          }
        }
        xdata = enterType.map((e) => {
          return { value: e.n, name: e.enter_type };
        });
        console.log(341, xdata);
        option.title.text = "企业类型";
        option.series[0].name = "企业类型";
        option.series[0].data = xdata;
        option.toolbox.feature.saveAsImage.name = "企业类型";
        this.$echarts.init(document.getElementById("yjChart21")).setOption(option);
      });
    },

    initMcolumn() {
      //多个柱子的图

      let xData = [],
        valueData = [[], [], []],
        pctData = [];

      let majorDic = {};
      let majors_bys = [];
      for (let item of this.DataInfo.majors_bys) {
        if (item.major_type != "总计") {
          majors_bys.push(item);
        }
      }
      this.DataInfo.majors_bys = majors_bys;

      let majors_jiuye = [];
      for (let item of this.DataInfo.majors_jiuye) {
        if (item.major_type != "总计") {
          majors_jiuye.push(item);
        }
      }
      this.DataInfo.majors_jiuye = majors_jiuye;
      console.log(1100, majors_bys);

      let majors_duikou = [];
      for (let item of this.DataInfo.majors_duikou) {
        if (item.zhuanye_type && item.zhuanye_type != "总计") {
          majors_duikou.push(item);
        }
      }
      this.DataInfo.majors_duikou = majors_duikou;

      for (let item of this.DataInfo.majors_bys) {
        if (!majorDic[item.major_type]) {
          majorDic[item.major_type] = { bys: item.bys_num, duikou: 0, jiuye: 0 };

          0;
        } else {
          majorDic[item.major_type].bys = item.bys_num;
        }
      }

      for (let item of this.DataInfo.majors_jiuye) {
        if (!majorDic[item.major_type]) {
          majorDic[item.major_type] = { bys: item.bys_num || 0, duikou: 0, jiuye: item.jiuye_num || 0 };
        } else {
          majorDic[item.major_type].jiuye = item.jiuye_num || 0;
        }
      }

      for (let item of this.DataInfo.majors_duikou) {
        if (!majorDic[item.zhuanye_type]) {
          majorDic[item.zhuanye_type] = { bys: item.n || 0, duikou: item.n || 0, jiuye: item.n || 0 };
        } else {
          majorDic[item.zhuanye_type].duikou = item.n || 0;
        }
      }
      console.log(1135, majorDic);

      let bysNum = 0
      let jyNum = 0
      let dkNum = 0
      for (let k in majorDic) {
        xData.push(k);
        valueData[0].push(majorDic[k].bys);
        valueData[1].push(majorDic[k].jiuye);
        valueData[2].push(majorDic[k].duikou);
        bysNum += majorDic[k].bys
        jyNum += majorDic[k].jiuye
        dkNum += majorDic[k].duikou
      }

      //计算就业率

      for (let i = 0; i < xData.length; i++) {
        let jyl = Math.round((valueData[1][i] / valueData[0][i]) * 10000) / 100;
        let dkl = Math.round((valueData[2][i] / valueData[0][i]) * 10000) / 100;
        let tdata = { name: xData[i], jyl: jyl || 0, dkl: dkl || 0, total: valueData[0][i] || 0, jycount: valueData[1][i] || 0, dkcount: valueData[2][i] || 0 };
        pctData.push(tdata);
      }

      pctData = pctData.sort((a, b) => {
        return b.jyl - a.jyl;
      });
      pctData.unshift({
        name: '平均就业率',
        jyl: Math.round(jyNum/bysNum*10000)/100
      })

      let option = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "white",
          },
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {
              title: "下载图片", //鼠标滑过之后文案
              name: "我的图表", //图表名称
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#fff",
            },
          },
          formatter: function (params) {
            let formaterstr = "",
              inner0 = "";
            inner0 = `<span>${params[0].value}%</span>`;
            formaterstr = inner0;
            // 这里去掉了 title，只保留了 series 名称和数据的描述
            return formaterstr;
          },
        },
        // legend: {
        //     left: "center",
        //     top: "5%",

        //     data: ['就业率', '对口率']
        // },
        grid: {
          top: 30,
          left: "5%",
          right: "5%",
          bottom: "25%",
        },

        xAxis: [
          {
            type: "category",
            data: pctData.map((e) => {
              return e.name;
            }),
            axisPointer: {
              type: "shadow",
            },
            itemStyle: {
              color: function (params) {
                return colors[params.dataIndex];
              },
              borderRadius: [10, 10, 0, 0],
              shadowBlur: 1, // 设置阴影模糊度
              shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
            },
            axisLabel: {
              rotate: 45, // 旋转角度为45度
              // color: "#ffffff"
              textStyle: {
                color: "#000", // 设置 x 轴文字颜色
                fontWeight: "bold",
              },
              formatter: function (value, index) {
                // 根据 value 或 index 自定义标签样式
                if (value == "平均对口就业率") {
                  return "{a|平均}" + "{b|对口就业率}";
                }
                if (value == "平均就业率") {
                  return "{a|平均}" + "{b|就业率}";
                }
                return value;
              },
              rich: {
                a: {
                  color: "#f56c6c",
                  // fontSize: 16,
                  fontWeight: "bold",
                },
                b: {
                  color: "#f56c6c",
                  // fontSize: 16,
                  fontWeight: "bold",
                },
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "百分比",
            splitLine: { show: false },
            axisLabel: {
              // color: 'white'
            },
          },
        ],
        color: ["#6055BB", "#2469f3", "#cccc99", "#ff6666", "#cccc00", "#996699", "#333333"],
        series: [
          {
            name: "就业率",
            type: "bar",
            barMaxWidth: "40",
            data: pctData.map((e) => {
              return e.jyl;
            }),
            label: {
              show: true,
              position: "inside",
            },
            itemStyle: {
              color: function (params) {
                if (params.name == "平均对口就业率" ||  params.name == "平均就业率") {
                  return "#f56c6c";
                }
              },
            },
          },
        ],
      };

      option.toolbox.feature.saveAsImage.name = "各专业大类就业率统计表";
      this.$echarts.init(document.getElementById("yjChart30")).setOption(option);

      option.title.text = "";
      option.series[0].name = "对口率";
      let pctData1 = pctData.sort((a, b) => {
        return b.dkl - a.dkl;
      });
      pctData1[0].name = '平均对口就业率'
      pctData1[0].dkl = Math.round(dkNum/bysNum*10000)/100
      option.xAxis[0].data = pctData1.map(e => {
        return e.name
      })
      option.series[0].data = pctData1.map((e) => {
        return e.dkl;
      });
      option.toolbox.feature.saveAsImage.name = "各专业大类对口率统计表";
      this.$echarts.init(document.getElementById("yjChart31")).setOption(option);
    },
    initColumn_2() {
      let xData = [];
      let xValue = [];

      let option = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "white",
          },
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {
              title: "下载图片", //鼠标滑过之后文案
              name: "我的图表", //图表名称
            },
          },
        },
        tooltip: {
          show: true,
        },
        color: ["#6055BB", "#2469f3", "#cccc99", "#ff6666", "#cccc00", "#996699", "#333333"],
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            color: "white",
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "white",
          },
          splitLine: { show: false },
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
            barWidth: 30,
            showBackground: true,

            itemStyle: {
              color: function (params) {
                return colors[params.dataIndex];
              },
              borderRadius: [10, 10, 0, 0],
              shadowBlur: 1, // 设置阴影模糊度
              shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
            },
            label: {
              show: true,
              position: "top",
              fontSize: 10,
            },
          },
        ],
      };
      //就业合同
      let getPropValue = (arr, name, code) => {
        let value = 0;
        for (let item of arr) {
          if (item[code] == name) {
            value = item.n;
            break;
          }
        }
        return value;
      };
      xData.push({ name: "未签合同", value: getPropValue(this.DataInfo.hetong, "未签合同", "hetong") });
      xData.push({ name: "1年及以内", value: getPropValue(this.DataInfo.hetong, "1年及以内", "hetong") });
      xData.push({ name: "1～2(含)年", value: getPropValue(this.DataInfo.hetong, "1～2(含)年", "hetong") });
      xData.push({ name: "2～3(含)年", value: getPropValue(this.DataInfo.hetong, "2～3(含)年", "hetong") });
      for (let item of xData) {
        if (item.value == NaN) {
          item.value = 0;
        }
      }
      xData = xData.sort((a, b) => {
        return b.value - a.value;
      });
      option.xAxis.data = xData.map((e) => {
        return e.name;
      });
      option.series[0].data = xData.map((e) => {
        return e.value;
      });
      option.title.text = "就业合同签订情况（人数）";

      // this.$echarts.init(document.getElementById('yjChart10')).setOption(option);

      //起薪情况
      xData = [];
      xData.push({ name: "2490及以下", value: getPropValue(this.DataInfo.salary, "2490及以下", "salary") });
      xData.push({ name: "2490～4000", value: getPropValue(this.DataInfo.salary, "2490～4000", "salary") });
      xData.push({ name: "4000～5000", value: getPropValue(this.DataInfo.salary, "4000～5000", "salary") });
      xData.push({ name: "5000～6000", value: getPropValue(this.DataInfo.salary, "5000～6000", "salary") });
      xData.push({ name: "6000以上", value: getPropValue(this.DataInfo.salary, "6000以上", "salary") });
      for (let item of xData) {
        if (item.value == NaN) {
          item.value = 0;
        }
      }
      xData = xData.sort((a, b) => {
        return b.value - a.value;
      });
      option.xAxis.data = xData.map((e) => {
        return e.name;
      });
      option.series[0].data = xData.map((e) => {
        return e.value;
      });
      option.title.text = "起薪情况（平均起薪" + this.DataInfo.salary_avg + "）";

      // this.$echarts.init(document.getElementById('yjChart11')).setOption(option);

      //社会保险情况（人数）
      xData = [];
      xData.push({ name: "没有社保", value: getPropValue(this.DataInfo.baoxian, "没有社保", "wuxian") });
      xData.push({ name: "三险", value: getPropValue(this.DataInfo.baoxian, "三险", "wuxian") });
      xData.push({ name: "五险", value: getPropValue(this.DataInfo.baoxian, "五险", "wuxian") });
      xData.push({ name: "三险一金", value: getPropValue(this.DataInfo.baoxian, "三险一金", "wuxian") });
      xData.push({ name: "五险一金", value: getPropValue(this.DataInfo.baoxian, "五险一金", "wuxian") });
      for (let item of xData) {
        if (item.value == NaN) {
          item.value = 0;
        }
      }
      xData = xData.sort((a, b) => {
        return b.value - a.value;
      });
      option.xAxis.data = xData.map((e) => {
        return e.name;
      });
      option.series[0].data = xData.map((e) => {
        return e.value;
      });
      option.title.text = "社会保险情况（人数）";

      // this.$echarts.init(document.getElementById('yjChart12')).setOption(option);

      //就业满意度情况（人数）
      xData = [];
      xData.push({ name: "无法评估", value: getPropValue(this.DataInfo.manyidu, "无法评估", "manyidu") });
      xData.push({ name: "不满意", value: getPropValue(this.DataInfo.manyidu, "不满意", "manyidu") });
      xData.push({ name: "比较满意", value: getPropValue(this.DataInfo.manyidu, "比较满意", "manyidu") });
      xData.push({ name: "满意", value: getPropValue(this.DataInfo.manyidu, "满意", "manyidu") });
      xData.push({ name: "非常满意", value: getPropValue(this.DataInfo.manyidu, "非常满意", "manyidu") });
      for (let item of xData) {
        if (item.value == NaN) {
          item.value = 0;
        }
      }
      xData = xData.sort((a, b) => {
        return b.value - a.value;
      });
      option.xAxis.data = xData.map((e) => {
        return e.name;
      });
      option.series[0].data = xData.map((e) => {
        return e.value;
      });
      option.title.text = "就业满意度情况（人数）";

      // this.$echarts.init(document.getElementById('yjChart13')).setOption(option);
    },
  },
  beforeDestroy: function () {},
};
</script>

<style scope lang="less" type="text/less">
.conbox {
  display: flex;
  /*color: #fff;
    padding: 0 50px;*/
}

.boxinner {
  padding: 30px 12px;
}

.boxtitle {
  height: 30px;
  padding-left: 10px;
  /*margin-bottom: 10px;
    background-image: linear-gradient(90deg, rgba(0, 160, 233, .3176470588235294), rgba(0, 160, 233, 0));*/
  line-height: 30px;
  font-size: 20px;
  font-weight: 700;
}

.title2 {
  background-image: linear-gradient(270deg, rgba(0, 160, 233, 0.3176470588235294), rgba(0, 160, 233, 0));
}

.chart {
  width: 50%;
  height: 300px;
}

.chartPie {
  width: 33.3%;
  height: 300px;
}
.color_hj {
  color: #6055bb !important;
  font-weight: 700;
}
</style>
